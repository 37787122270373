import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import '../styles/ServicosTable.css'; 
import config from '../config';
import servicosImage from '../assets/servicosImage.png'
const apiUrl = config.apiUrl;
const ServicosTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    fetch(`${apiUrl}/servicos`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => window.location.reload());
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ativo',
        accessor: 'ativo',
        Cell: ({ value }) => (
          <span className={value ? 'sim' : 'nao'}>{value ? 'Sim' : 'Não'}</span>
        )
      },
      {
        Header: 'Serviço',
        accessor: 'servico'
      }
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className='servicos'>
      <h1>Serviços Contratados</h1>
      <img src={servicosImage} alt="servicosImage" className="servicos-image" />
      <div className='table-container'>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServicosTable;
