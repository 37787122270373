import React, { useState, useEffect } from 'react';
import '../styles/Atendentes.css'; // Importar o arquivo CSS para estilização
import config from '../config';
import atendimentoImage from '../assets/atendimentoImage.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Importar o estilo do toast

const apiUrl = config.apiUrl;

const Atendentes = () => {
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [setor, setSetor] = useState('SAC');
  const [atendentes, setAtendentes] = useState([]);
  const [editingAtendente, setEditingAtendente] = useState(null); // Estado para armazenar o atendente que está sendo editado

  useEffect(() => {
    fetchAtendentes();
  }, []);

  const fetchAtendentes = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/atendente`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setAtendentes(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      window.location.reload();
    }
  };

  const formatTelefone = (telefone) => {
    const numericValue = telefone.replace(/\D/g, '');

    // Adiciona o "+" no início
    let formattedTelefone = '+' + numericValue.slice(0, 2) + ' ' + numericValue.slice(2, 4) + ' ';

    if (numericValue.length === 13) {
      // Número de celular: +55 XX 9XXXX-XXXX
      const primeiraParte = numericValue.slice(4, 9); // 9XXXX
      const segundaParte = numericValue.slice(9); // XXXX
      formattedTelefone += primeiraParte + '-' + segundaParte;
    } else if (numericValue.length === 12) {
      // Número fixo: +55 XX XXXX-XXXX
      const primeiraParte = numericValue.slice(4, 8); // XXXX
      const segundaParte = numericValue.slice(8); // XXXX
      formattedTelefone += primeiraParte + '-' + segundaParte;
    } else {
      // Caso não seja um número válido, retorna o número original
      return telefone;
    }

    return formattedTelefone;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    // Formatar o número de telefone antes de enviar
    const formattedTelefone = formatTelefone(telefone);

    // Verificação: Se o número formatado é igual ao número original, é inválido
    if (formattedTelefone === telefone) {
      toast.error('Número de telefone inválido. Por favor, insira um número correto somente com algarismos.');
      return; // Não prosseguir com a requisição
    }

    try {
      const response = await fetch(`${apiUrl}/atendente`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nome, telefone: formattedTelefone, setor }),
      });

      if (response.ok) {
        const newAtendente = await response.json();
        setAtendentes([...atendentes, newAtendente]);
        setNome('');
        setTelefone('');
        setSetor('SAC');
        toast.success('Atendente adicionado com sucesso!');
      } else {
        console.error('Failed to add atendente');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao adicionar atendente.');
      window.location.reload();
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${apiUrl}/atendente/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) {
        setAtendentes(atendentes.filter((atendente) => atendente.id !== id));
        toast.success('Atendente excluído com sucesso!');
      } else {
        console.error('Failed to delete atendente');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao excluir atendente.');
      window.location.reload();
    }
  };

  const handleTelefoneChange = (e) => {
    // Permitir apenas números
    const value = e.target.value.replace(/\D/g, '');
    setTelefone(value);
  };

  const handleEditClick = (atendente) => {
    setEditingAtendente({ ...atendente, telefone: formatTelefone(atendente.telefone) }); // Configurar o atendente a ser editado
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    // Formatar o número de telefone antes de enviar
    const formattedTelefone = formatTelefone(editingAtendente.telefone);

    try {
      const response = await fetch(`${apiUrl}/atendente/${editingAtendente.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...editingAtendente, telefone: formattedTelefone }),
      });

      if (response.ok) {
        const updatedAtendente = await response.json();
        setAtendentes(atendentes.map((atendente) =>
          atendente.id === updatedAtendente.id ? updatedAtendente : atendente
        ));
        setEditingAtendente(null);
        toast.success('Atendente atualizado com sucesso!');
      } else {
        console.error('Failed to update atendente');
        toast.error('Erro ao atualizar atendente.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Erro ao atualizar atendente.');
      window.location.reload();
    }
  };

  return (
    <div className="container">
      <h1>Cadastro de Atendentes</h1>
      <img src={atendimentoImage} alt="Atendimento" className="atendimento-image" />
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label>Nome:</label>
          <input
            type="text"
            value={nome}
            placeholder='Ex: Luís'
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Telefone:</label>
          <input
            type="text"
            value={telefone}
            placeholder='Ex: 28999272312'
            onChange={handleTelefoneChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Setor:</label>
          <select value={setor} onChange={(e) => setSetor(e.target.value)}>
            <option value="SAC">SAC</option>
            <option value="FINANCEIRO">FINANCEIRO</option>
            <option value="COMERCIAL">COMERCIAL</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Adicionar Atendente</button>
      </form>

      <h2>Lista de Atendentes</h2>
      {atendentes.length === 0 ? (
        <p>Nenhum atendente cadastrado</p>
      ) : (
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Setor</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {atendentes.map((atendente) => (
                <tr key={atendente.id}>
                  <td>{atendente.nome}</td>
                  <td>{atendente.telefone}</td>
                  <td>{atendente.setor}</td>
                  <td>
                    <button
                      onClick={() => handleEditClick(atendente)}
                      className="edit-button"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleDelete(atendente.id)}
                      className="delete-button"
                    >
                      Excluir
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {editingAtendente && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>Editar Atendente</h2>
            <form onSubmit={handleUpdate}>
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  value={editingAtendente.nome}
                  onChange={(e) =>
                    setEditingAtendente({ ...editingAtendente, nome: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label>Setor:</label>
                <select
                  value={editingAtendente.setor}
                  onChange={(e) =>
                    setEditingAtendente({ ...editingAtendente, setor: e.target.value })
                  }
                >
                  <option value="SAC">SAC</option>
                  <option value="FINANCEIRO">FINANCEIRO</option>
                  <option value="COMERCIAL">COMERCIAL</option>
                </select>
              </div>
              <button type="submit" className="update-button">Atualizar</button>
              <button type="button" className="cancel-button" onClick={() => setEditingAtendente(null)}>Cancelar</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Atendentes;
