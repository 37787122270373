import React, { useState, useEffect } from 'react';
import LineChart from './FeedLineChart';
import PieChart from './FeedPieChart';
import PeriodSelector from './FeedPeriodSelector';
import axios from 'axios';
import config from '../config';
import '../styles/FeedBackDashboard.css';

const FeedBackDashboard = () => {
  const [periodoMensagens, setPeriodoMensagens] = useState('');
  const [periodoFeedbacks, setPeriodoFeedbacks] = useState('');
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/feedback/feedbacks`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            mes: periodoFeedbacks.split('/')[0], // Mês
            ano: periodoFeedbacks.split('/')[1]  // Ano
          }
        });
        setFeedbacks(response.data);
      } catch (error) {
        console.error('Erro ao buscar feedbacks:', error);
        window.location.reload();
      }
    };

    if (periodoFeedbacks) {
      fetchFeedbacks();
    }
  }, [periodoFeedbacks]);

  return (
    <div className="dashboard-container">
      <div className="chart-container">
        <h2>Mensagens por Período</h2>
        <PeriodSelector onSelect={setPeriodoMensagens} apiUrl="historico" />
        {periodoMensagens && <LineChart periodo={periodoMensagens} />}
      </div>

      <div className="chart-container">
        <h2>Nível de Satisfação dos Feedbacks</h2>
        <PeriodSelector onSelect={setPeriodoFeedbacks} apiUrl="feedback" />
        {periodoFeedbacks && <PieChart periodo={periodoFeedbacks} />}
      </div>

      <div className="chart-container">
        <h2>Feedbacks Detalhados</h2>
        <PeriodSelector onSelect={setPeriodoFeedbacks} apiUrl="feedback" />
        {feedbacks.length > 0 ? (
          <table className="feedbacks-table">
            <thead>
              <tr>
                <th>Autor</th>
                <th>Nível de Satisfação</th>
                <th>Observação</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {feedbacks.map((feedback, index) => (
                <tr key={index}>
                  <td>{feedback.autor}</td>
                  <td>{feedback.nivelsatisfacao}</td>
                  <td>{feedback.observacao}</td>
                  <td>{new Date(feedback.data).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Nenhum feedback encontrado para o período selecionado.</p>
        )}
      </div>
    </div>
  );
};

export default FeedBackDashboard;
