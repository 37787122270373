import React, { useState } from 'react';
import axios from 'axios';
import '../styles/EditCampanha.css';
import config from '../config';

const apiUrl = config.apiUrl;

const EditCampanha = ({ campanha, handleClose, onUpdateSuccess }) => {
  const [nome, setNome] = useState(campanha.nome);
  const [classificacao, setClassificacao] = useState(campanha.classificacao);
  const [conteudo, setConteudo] = useState(campanha.conteudo);
  const [imagem, setImagem] = useState(null);

  const classificacoes = [
    'Pesquisa de Satisfação',
    'Campanha Publicitária',
    'Mudança de Status do Pedido',
    'Aviso de Boleto em Vencimento ou Próximo'
  ];

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('dados', new Blob([JSON.stringify({ id: campanha.id, nome, classificacao, conteudo })], { type: 'application/json' }));
    if (imagem) {
      formData.append('imagem', imagem);
    }

    try {
      const token = localStorage.getItem('token');
      await axios.put(`${apiUrl}/campanhas/atualizarcampanha`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      onUpdateSuccess();
      handleClose();
    } catch (error) {
      console.error('Erro ao atualizar campanha:', error);
      window.location.reload();
    }
  };

  return (
    <div className="edit-campanha-overlay">
      <div className="edit-campanha-container">
        <button className="close-button" onClick={handleClose}>Fechar</button>
        <form className="edit-campanha-form" onSubmit={handleUpdate}>
          <div className="form-group">
            <label htmlFor="nome">Nome</label>
            <input type="text" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="classificacao">Classificação</label>
            <select id="classificacao" value={classificacao} onChange={(e) => setClassificacao(e.target.value)} required>
              <option value="">Selecione uma classificação</option>
              {classificacoes.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="conteudo">Conteúdo</label>
            <textarea id="conteudo" value={conteudo} onChange={(e) => setConteudo(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="imagem">Imagem</label>
            <input type="file" id="imagem" onChange={(e) => setImagem(e.target.files[0])} accept="image/*" />
          </div>
          <button type="submit" className="edit-campanha-button">Atualizar Campanha</button>
        </form>
      </div>
    </div>
  );
};

export default EditCampanha;
