import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Campanha.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config';
import Popup from './Popup';
import EditCampanha from './EditCampanha';
import campanhasImage from '../assets/imageCampanhas.png'
const apiUrl = config.apiUrl;

const Campanha = () => {
  const [nome, setNome] = useState('');
  const [classificacao, setClassificacao] = useState('');
  const [ativo, setAtivo] = useState(false);
  const [conteudo, setConteudo] = useState('');
  const [imagem, setImagem] = useState(null);
  const [campanhas, setCampanhas] = useState([]);
  const [viewCampanha, setViewCampanha] = useState(null); // Estado para o popup de visualização
  const [imagemSrc, setImagemSrc] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editCampanha, setEditCampanha] = useState(null); // Estado para o popup de edição

  const classificacoes = [
    'Pesquisa de Satisfação',
    'Campanha Publicitária',
    'Mudança de Status do Pedido',
    'Aviso de Boleto em Vencimento ou Próximo',
    'Outro'
  ];

  useEffect(() => {
    fetchCampanhas();
  }, []);
  
  const fetchCampanhas = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/campanhas/getall`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCampanhas(response.data);
    } catch (error) {
      console.error('Erro ao buscar campanhas:', error);
      window.location.reload();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('dados', new Blob([JSON.stringify({ nome, classificacao, ativo, conteudo })], { type: 'application/json' }));
    formData.append('imagem', imagem);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${apiUrl}/campanhas/criarcampanha`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Campanha criada com sucesso:', response.data);
      fetchCampanhas();
    } catch (error) {
      console.error('Erro ao criar campanha:', error);
      window.location.reload();
    }
  };

  const toggleAtivo = async (campanhaId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${apiUrl}/campanhas/toggleAtivo/${campanhaId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchCampanhas();
    } catch (error) {
      console.error('Erro ao ativar/desativar campanha:', error);
      window.location.reload();
    }
  };

  const handleViewContent = async (campanha) => {
    if (isEditing) return; // Verifica se está em modo de edição

    setViewCampanha(campanha);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/campanhas/imagem/${campanha.id}`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const imageUrl = URL.createObjectURL(response.data);
      setImagemSrc(imageUrl);
    } catch (error) {
      console.error('Erro ao buscar imagem:', error);
      window.location.reload();
    }
  };

  const handleCloseViewPopup = () => {
    setViewCampanha(null);
    setImagemSrc('');
  };

  const handleEdit = (campanha) => {
    setEditCampanha(campanha);
    setIsEditing(true);
  };

  const handleUpdateSuccess = () => {
    fetchCampanhas();
    toast.success('Campanha atualizada com sucesso!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: 'green',
        color: 'white'
      },
    });
    setIsEditing(false);
    setEditCampanha(null); // Certifica-se de que o popup de visualização não abra após a edição
  };

  return (
    <div className="campanha-container">
      <h2>Criar Nova Campanha</h2>
      <img src={campanhasImage} alt="campanhasImage" className="campanhas-image" />
      <form className="campanha-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nome">Nome</label>
          <input type="text" id="nome" value={nome} onChange={(e) => setNome(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="classificacao">Classificação</label>
          <select id="classificacao" value={classificacao} onChange={(e) => setClassificacao(e.target.value)} required>
            <option value="">Selecione uma classificação</option>
            {classificacoes.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="ativo">Ativo</label>
          <input type="checkbox" id="ativo" checked={ativo} onChange={(e) => setAtivo(e.target.checked)} />
        </div>
        <div className="form-group">
          <label htmlFor="conteudo">Conteúdo</label>
          <textarea id="conteudo" value={conteudo} onChange={(e) => setConteudo(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="imagem">Imagem</label>
          <input type="file" id="imagem" onChange={(e) => setImagem(e.target.files[0])} accept="image/*" required />
        </div>
        <button type="submit" className="campanha-button">Criar Campanha</button>
      </form>

      <h2>Campanhas Criadas</h2>
      <table className="campanha-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Classificação</th>
            <th>Ativo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {campanhas.map(campanha => (
            <tr key={campanha.id}>
              <td>{campanha.nome}</td>
              <td>{campanha.classificacao}</td>
              <td>{campanha.ativo ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => handleViewContent(campanha)} className="view-button22">
                  Ver Conteúdo
                </button>
                <button 
                  onClick={() => toggleAtivo(campanha.id)}
                  className={campanha.ativo ? 'deactivate-button' : 'activate-button'}
                >
                  {campanha.ativo ? 'Desativar' : 'Ativar'}
                </button>
                <button onClick={() => handleEdit(campanha)} className="edit-button">
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {viewCampanha && !isEditing && (
        <Popup 
          selectedCampanha={viewCampanha} 
          imagemSrc={imagemSrc} 
          handleClosePopup={handleCloseViewPopup} 
        />
      )}

      {isEditing && editCampanha && (
        <EditCampanha 
          campanha={editCampanha} 
          handleClose={() => {
            setIsEditing(false);
            setEditCampanha(null);
          }} 
          onUpdateSuccess={handleUpdateSuccess} 
        />
      )}
    </div>
  );
};

export default Campanha;
