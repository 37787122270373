// ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../styles/ForgotPassword.css';
import config from '../config';
const apiUrl = config.apiUrl;
const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${apiUrl}/auth/forgot-password`, { email });
      toast.success('Email de recuperação enviado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'green',
          color: 'white',
        },
      });
    } catch (error) {
      toast.error('Seu email não foi encontrado na nossa base de dados.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white',
        },
      });
    }
  };

  return (
    <div className="forgot-password-container">
      <h2>Esqueci minha senha</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="forgot-password-button">Enviar</button>
      </form>
    </div>
  );
};

export default ForgotPassword;
