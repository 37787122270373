import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/RegisterAdmin.css';
import { toast } from 'react-toastify';
import config from '../config';

const apiUrl = config.apiUrl;

const RegisterAdmin = () => {
  const [nome, setNome] = useState('');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [ipbanco, setIpbanco] = useState('');
  const [userbanco, setUserbanco] = useState('');
  const [senhabanco, setSenhabanco] = useState('');
  const [iptomcat, setIptomcat] = useState('');
  const [portatomcat, setPortatomcat] = useState('');
  const [erp, setErp] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [editingUser, setEditingUser] = useState(null);

  const erpOptions = ['FoccoERP', 'OmieERP'];

  const fetchUsuarios = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Token de autenticação não encontrado. Faça login novamente.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      return;
    }

    try {
      const response = await axios.get(`${apiUrl}/user/all`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setUsuarios(response.data);
    } catch (error) {
      toast.error('Erro ao buscar usuários: ' + (error.response?.data?.message || error.message), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      console.error('Erro ao buscar usuários:', error);
      window.location.reload();
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Token de autenticação não encontrado. Faça login novamente.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      return;
    }

    try {
      await axios.post(`${apiUrl}/auth/register`, {
        nome,
        login,
        password,
        role: 'USER',
        ipbanco,
        userbanco,
        senhabanco,
        iptomcat,
        portatomcat,
        erp
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      toast.success('Usuário registrado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'green',
          color: 'white'
        },
      });
      // Atualiza a lista de usuários
      fetchUsuarios();
    } catch (error) {
      toast.error('Erro ao registrar usuário: ' + (error.response?.data?.message || error.message), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      console.error('Erro ao registrar usuário:', error);
    }
  };

  const toggleUserStatus = async (userId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(`${apiUrl}/user/${userId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setUsuarios(usuarios.map(user => user.id === userId ? response.data : user));

      toast.success(`Usuário ${response.data.inativo ? 'inativado' : 'ativado'} com sucesso!`, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'green',
          color: 'white'
        },
      });
    } catch (error) {
      toast.error('Erro ao alterar status do usuário: ' + (error.response?.data?.message || error.message), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      console.error('Erro ao alterar status do usuário:', error);
    }
  };

  const deleteUser = async (userId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${apiUrl}/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setUsuarios(usuarios.filter(user => user.id !== userId));

      toast.success('Usuário deletado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'green',
          color: 'white'
        },
      });
    } catch (error) {
      toast.error('Erro ao deletar usuário: ' + (error.response?.data?.message || error.message), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      console.error('Erro ao deletar usuário:', error);
    }
  };

  const editUser = async (userId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/auth/getuser-info?id=${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setEditingUser(response.data);
    } catch (error) {
      toast.error('Erro ao buscar informações do usuário: ' + (error.response?.data?.message || error.message), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      console.error('Erro ao buscar informações do usuário:', error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      toast.error('Token de autenticação não encontrado. Faça login novamente.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      return;
    }

    try {
      await axios.put(`${apiUrl}/auth/update-user-info`, editingUser, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      toast.success('Usuário atualizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'green',
          color: 'white'
        },
      });
      setEditingUser(null);
      fetchUsuarios();
    } catch (error) {
      toast.error('Erro ao atualizar usuário: ' + (error.response?.data?.message || error.message), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white'
        },
      });
      console.error('Erro ao atualizar usuário:', error);
    }
  };

  return (
    <div className="register-container">
      <h2>Registrar Novo Usuário</h2>
      <form className="register-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            id="nome"
            name="nome"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="login">Login (email)</label>
          <input
            type="text"
            id="login"
            name="login"
            placeholder="Email"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ipbanco">IP do Banco</label>
          <input
            type="text"
            id="ipbanco"
            name="ipbanco"
            placeholder="IP do Banco"
            value={ipbanco}
            onChange={(e) => setIpbanco(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="userbanco">Usuário do Banco</label>
          <input
            type="text"
            id="userbanco"
            name="userbanco"
            placeholder="Usuário do Banco"
            value={userbanco}
            onChange={(e) => setUserbanco(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="senhabanco">Senha do Banco</label>
          <input
            type="password"
            id="senhabanco"
            name="senhabanco"
            placeholder="Senha do Banco"
            value={senhabanco}
            onChange={(e) => setSenhabanco(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="iptomcat">IP do Tomcat</label>
          <input
            type="text"
            id="iptomcat"
            name="iptomcat"
            placeholder="IP do Tomcat"
            value={iptomcat}
            onChange={(e) => setIptomcat(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="portatomcat">Porta do Tomcat</label>
          <input
            type="text"
            id="portatomcat"
            name="portatomcat"
            placeholder="Porta do Tomcat"
            value={portatomcat}
            onChange={(e) => setPortatomcat(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="erp">ERP</label>
          <select
            id="erp"
            name="erp"
            value={erp}
            onChange={(e) => setErp(e.target.value)}
            required
          >
            <option value="">Selecione um ERP</option>
            {erpOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="register-button">Registrar</button>
      </form>

      <h2>Usuários</h2>
      <table className="user-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Status</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map(usuario => (
            <tr key={usuario.id}>
              <td>{usuario.nome}</td>
              <td>{usuario.inativo ? 'Inativo' : 'Ativo'}</td>
              <td>
                <button
                  className={usuario.inativo ? 'activate-button' : 'deactivate-button'}
                  onClick={() => toggleUserStatus(usuario.id)}
                >
                  {usuario.inativo ? 'Ativar' : 'Inativar'}
                </button>
                <button
                  className="delete-button"
                  onClick={() => deleteUser(usuario.id)}
                >
                  Deletar
                </button>
                <button
                  className="edit-button"
                  onClick={() => editUser(usuario.id)}
                >
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingUser && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>Editar Usuário</h2>
            <form onSubmit={handleUpdate}>
              <div className="form-group">
                <label htmlFor="edit-nome">Nome</label>
                <input
                  type="text"
                  id="edit-nome"
                  name="edit-nome"
                  value={editingUser.nome}
                  onChange={(e) => setEditingUser({ ...editingUser, nome: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-login">Login (email)</label>
                <input
                  type="text"
                  id="edit-login"
                  name="edit-login"
                  value={editingUser.login}
                  onChange={(e) => setEditingUser({ ...editingUser, login: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-ipbanco">IP do Banco</label>
                <input
                  type="text"
                  id="edit-ipbanco"
                  name="edit-ipbanco"
                  value={editingUser.ipbanco}
                  onChange={(e) => setEditingUser({ ...editingUser, ipbanco: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-userbanco">Usuário do Banco</label>
                <input
                  type="text"
                  id="edit-userbanco"
                  name="edit-userbanco"
                  value={editingUser.userbanco}
                  onChange={(e) => setEditingUser({ ...editingUser, userbanco: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-senhabanco">Senha do Banco</label>
                <input
                  type="text"
                  id="edit-senhabanco"
                  name="edit-senhabanco"
                  value={editingUser.senhabanco}
                  onChange={(e) => setEditingUser({ ...editingUser, senhabanco: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-iptomcat">IP do Tomcat</label>
                <input
                  type="text"
                  id="edit-iptomcat"
                  name="edit-iptomcat"
                  value={editingUser.iptomcat}
                  onChange={(e) => setEditingUser({ ...editingUser, iptomcat: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-portatomcat">Porta do Tomcat</label>
                <input
                  type="text"
                  id="edit-portatomcat"
                  name="edit-portatomcat"
                  value={editingUser.portatomcat}
                  onChange={(e) => setEditingUser({ ...editingUser, portatomcat: e.target.value })}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="edit-erp">ERP</label>
                <select
                  id="edit-erp"
                  name="edit-erp"
                  value={editingUser.erp}
                  onChange={(e) => setEditingUser({ ...editingUser, erp: e.target.value })}
                  required
                >
                  <option value="">Selecione um ERP</option>
                  {erpOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <button type="submit" className="update-button">Atualizar</button>
              <button type="button" className="cancel-button" onClick={() => setEditingUser(null)}>Cancelar</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterAdmin;
