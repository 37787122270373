import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/ManageParametros.css';
import config from '../config';
import parametrosImage from '../assets/parametrosImage.png'
const apiUrl = config.apiUrl;
const ManageParametros = () => {
  const [parametros, setParametros] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    fetch(`${apiUrl}/parametros`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => setParametros(data))
      .catch(error => window.location.reload());
  }, []);

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditValue(parametros[index].valor);
  };

  const handleCancel = () => {
    setEditIndex(-1);
    setEditValue('');
  };

  const handleSave = async (index) => {
    const token = localStorage.getItem('token');
    const parametro = parametros[index];

    try {
      await axios.put(`${apiUrl}/parametros`, {
        parametroId: parametro.id,
        novoValor: editValue
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const updatedParametros = [...parametros];
      updatedParametros[index].valor = editValue;
      setParametros(updatedParametros);
      setEditIndex(-1);
      setEditValue('');
    } catch (error) {
      console.error('Error updating parametro:', error);
    }
  };

  return (
    <div className="manage-parametros-container">
      <h2>Gerenciar Parâmetros</h2>
      <img src={parametrosImage} alt="parametros" className="param-image" />
      <div className="parametros-list">
        {parametros.map((parametro, index) => (
          <div key={parametro.id} className="parametro-item">
            <h3>{parametro.parametro}</h3>
            {editIndex === index ? (
              <div className="edit-area">
                <textarea
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />
                <div>
                  <button
                    className="save-button"
                    onClick={() => handleSave(index)}
                  >
                    Salvar
                  </button>
                  <button
                    className="cancel-button"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              <div className="parametro-content">
                <p>{parametro.valor}</p>
                <button
                  className="edit-button"
                  onClick={() => handleEdit(index)}
                >
                  Editar
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageParametros;
