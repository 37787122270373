import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import UserDashboard from './components/UserDashboard';
import Home from './components/Home';
import AdminDashboard from './components/AdminDashboard';
import ResetPassword from './components/ResetPassword'; 
import ForgotPassword from './components/ForgotPassword'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userName, setUserName] = useState('');

  const handleLoginSuccess = (name) => {
    setIsAuthenticated(true);
    setUserName(name);
  };

  const handleAdminCheck = (isAdminValue) => {
    setIsAdmin(isAdminValue);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setIsAdmin(false);
    setUserName('');
  };

  return (
    <Router>
      <div className="App">
        <ToastContainer />
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} /> 
          <Route path="/" element={<Home/>}/>
          <Route
            path="/cliente"
            element={
              isAuthenticated ? (
                isAdmin ? (
                  <AdminDashboard userName={userName} onLogout={handleLogout} />
                ) : (
                  <UserDashboard userName={userName} onLogout={handleLogout} />
                )
              ) : (
                <Login onLoginSuccess={handleLoginSuccess} onAdminCheck={handleAdminCheck} />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
