import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import '../styles/Seletores.css';

const Seletores = () => {
  const [seletores, setSeletores] = useState([]);
  const [versionamentos, setVersionamentos] = useState([]);
  const [editingSeletor, setEditingSeletor] = useState(null);
  const [editedValue, setEditedValue] = useState('');

  useEffect(() => {
    const fetchSeletores = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/seletores`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSeletores(response.data);
      } catch (error) {
        toast.error('Erro ao carregar seletores', { position: 'top-right' });
      }
    };

    const fetchVersionamentos = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/versionamento/last-two`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setVersionamentos(response.data);
      } catch (error) {
        toast.error('Erro ao carregar versionamentos', { position: 'top-right' });
      }
    };

    fetchSeletores();
    fetchVersionamentos();
  }, []);

  const handleEdit = (seletor) => {
    setEditingSeletor(seletor.id);
    setEditedValue(seletor.value);
  };

  const handleCancel = () => {
    setEditingSeletor(null);
    setEditedValue('');
  };

  const handleSave = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${config.apiUrl}/seletores/atualizar`, 
        { id, valor: editedValue }, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSeletores(
        seletores.map((seletor) =>
          seletor.id === id ? { ...seletor, value: editedValue } : seletor
        )
      );
      setEditingSeletor(null);
      toast.success('Seletor atualizado com sucesso', { position: 'top-right' });
    } catch (error) {
      toast.error('Erro ao atualizar seletor', { position: 'top-right' });
    }
  };

  return (
    <div className="seletores-container">
      <h2>Gerenciamento de Versionamento</h2>
      <table className="versionamentos-table">
        <thead>
          <tr>
            <th>Versão</th>
            <th>Remetente</th>
            <th>Data de Atualização</th>
          </tr>
        </thead>
        <tbody>
          {versionamentos.map((versionamento) => (
            <tr key={versionamento.id}>
              <td>{versionamento.versao}</td>
              <td>{versionamento.remetente}</td>
              <td>{new Date(versionamento.data).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2 className="section-spacing">Gerenciamento de Seletores</h2>
      <table className="seletores-table">
        <thead>
          <tr>
            <th className="key-column">Key</th>
            <th className="value-column">Value</th>
            <th className="actions-column">Ações</th>
          </tr>
        </thead>
        <tbody>
          {seletores.map((seletor) => (
            <tr key={seletor.id}>
              <td className="key-column">{seletor.key}</td>
              <td className="value-column">
                {editingSeletor === seletor.id ? (
                  <input
                    type="text"
                    value={editedValue}
                    onChange={(e) => setEditedValue(e.target.value)}
                  />
                ) : (
                  seletor.value
                )}
              </td>
              <td className="actions-column">
                {editingSeletor === seletor.id ? (
                  <>
                    <button onClick={() => handleSave(seletor.id)} className="save-button">Salvar</button>
                    <button onClick={handleCancel} className="cancel-button">Cancelar</button>
                  </>
                ) : (
                  <button onClick={() => handleEdit(seletor)} className="edit-button">Editar</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Seletores;
