import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/NivelAutenticacao.css';
import config from '../config';
import authImage from '../assets/authImage.png'

const apiUrl = config.apiUrl;

const NivelAutenticacao = () => {
  const [authList, setAuthList] = useState([]);

  useEffect(() => {
    fetchUserAuth();
  }, []);

  const fetchUserAuth = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/nivel-autenticacao/user-auth`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setAuthList(response.data);
    } catch (error) {
      console.error('Erro ao buscar níveis de autenticação:', error);
    }
  };

  const toggleAuth = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${apiUrl}/nivel-autenticacao/mudar/${id}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchUserAuth();
    } catch (error) {
      console.error('Erro ao mudar nível de autenticação:', error);
    }
  };

  return (
    <div className="auth-container">
      <h2>Gerenciar Níveis de Autenticação</h2>
      <img src={authImage} alt="auth" className="auth-image" />
      <table className="auth-table">
        <thead>
          <tr>
            <th>Nome do Serviço</th>
            <th>Ativo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {authList.map(auth => (
            <tr key={auth.id}>
              <td>{auth.nomeServico}</td>
              <td>{auth.ativo ? 'Sim' : 'Não'}</td>
              <td>
                <button 
                  onClick={() => toggleAuth(auth.id)}
                  className={auth.ativo ? 'deactivate-button' : 'activate-button'}
                >
                  {auth.ativo ? 'Desativar' : 'Ativar'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NivelAutenticacao;
