import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import config from '../config';

const FeedPieChart = ({ periodo }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'pie'
      },
      labels: [],
      title: {
        text: 'Nível de Satisfação dos Feedbacks',
        align: 'center'
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiUrl}/feedback/pie`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { periodo }
      });

      const series = response.data.map(item => item.quantidade);
      const labels = response.data.map(item => item.nivelsatisfacao);
      
      setChartData({
        series,
        options: { ...chartData.options, labels }
      });
    };

    fetchData();
  }, [periodo]);

  return (
    <div>
      <Chart options={chartData.options} series={chartData.series} type="pie" height={350} />
    </div>
  );
};

export default FeedPieChart;
