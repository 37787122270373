import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import '../styles/Disparo.css';
import config from '../config';
import disparosImage from '../assets/imageDisparos.png'
const apiUrl = config.apiUrl;

const Disparo = () => {
  const [campanhaId, setCampanhaId] = useState('');
  const [tipodisparo, setTipodisparo] = useState('');
  const [dataenvio, setDataenvio] = useState('');
  const [horainicial, setHorainicial] = useState('09:00');
  const [horafinal, setHorafinal] = useState('18:00');
  const [ativo, setAtivo] = useState(false);
  const [repetir, setRepetir] = useState(false);
  const [codigos, setCodigos] = useState(null);
  const [uf, setUf] = useState(null);
  const [diasatraso, setDiasatraso] = useState(null);
  const [diassemcompra, setDiassemcompra] = useState(null);
  const [segmento, setSegmento] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [representante, setRepresentante] = useState(null);
  const [boletovenceem, setBoletovenceem] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [campanhas, setCampanhas] = useState([]);
  const [disparos, setDisparos] = useState([]);

  const classificacoes = [
    'Clientes',
    'Fornecedor',
    'Representante',
    'Funcionários'
  ];

  useEffect(() => {
    fetchCampanhas();
    fetchDisparos();
  }, []);

  const fetchCampanhas = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/campanhas/getallativos`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCampanhas(response.data);
    } catch (error) {
      console.error('Erro ao buscar campanhas:', error);
      window.location.reload();
    }
  };

  const fetchDisparos = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/disparos/getall`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setDisparos(response.data);
    } catch (error) {
      console.error('Erro ao buscar disparos:', error);
      window.location.reload();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const disparoData = {
      campanhaId,
      tipodisparo,
      dataenvio,
      horainicial,
      horafinal,
      ativo,
      repetir,
      codigos,
      uf,
      diasatraso: parseInt(diasatraso) || 0,
      diassemcompra: parseInt(diassemcompra) || 0,
      segmento,
      tipo,
      representante,
      boletovenceem: parseInt(boletovenceem) || 0,
      ranking: parseInt(ranking) || 0
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${apiUrl}/disparos/criardisparo`, disparoData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Disparo criado com sucesso:', response.data);
      fetchDisparos();
    } catch (error) {
      console.error('Erro ao criar disparo:', error);
      window.location.reload();
    }
  };

  const toggleAtivo = async (disparoId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${apiUrl}/disparos/toggleAtivo/${disparoId}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      fetchDisparos();
    } catch (error) {
      console.error('Erro ao ativar/desativar disparo:', error);
      window.location.reload();
    }
  };

  return (
    <div className="disparo-container">
      <h2>Criar Novo Disparo</h2>
      <img src={disparosImage} alt="disparosImage" className="disparos-image" />
      <form className="disparo-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="campanhaId">Campanha</label>
          <select id="campanhaId" value={campanhaId} onChange={(e) => setCampanhaId(e.target.value)} required>
            <option value="">Selecione uma campanha</option>
            {campanhas.map((campanha, index) => (
              <option key={index} value={campanha.id}>{campanha.nome}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tipodisparo">Tipo de Disparo</label>
          <select id="tipodisparo" value={tipodisparo} onChange={(e) => setTipodisparo(e.target.value)} required>
            <option value="">Selecione um tipo de disparo</option>
            {classificacoes.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </div>
        {/* Inputs dinâmicos baseados no tipo de disparo */}
        {tipodisparo === 'Clientes' && (
          <>
            <div className="form-group">
              <label htmlFor="codigos">Códigos do cliente</label>
              <input type="text" id="codigos" value={codigos} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setCodigos(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="uf">UF</label>
              <input type="text" id="uf" value={uf} placeholder="Digite mais de um valor separando por vírgula, exemplo: RJ, SP, SC" onChange={(e) => setUf(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="segmento">Segmento</label>
              <input type="text" id="segmento" value={segmento} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setSegmento(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="diasatraso">Dias de Atraso Boleto</label>
              <input type="number" id="diasatraso" value={diasatraso} onChange={(e) => setDiasatraso(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="diassemcompra">Dias Sem Comprar</label>
              <input type="number" id="diassemcompra" value={diassemcompra} onChange={(e) => setDiassemcompra(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="boletovenceem">Boleto Vence em:</label>
              <input type="number" id="boletovenceem" value={boletovenceem} onChange={(e) => setBoletovenceem(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="representante">Código(s) do Representante</label>
              <input type="text" id="representante" value={representante} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setRepresentante(e.target.value)}  />
            </div>
            <div className="form-group">
              <label htmlFor="tipo">Tipo de cliente</label>
              <input type="text" id="tipo" value={tipo} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setTipo(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="ranking">Ranking</label>
              <input type="number" id="ranking" value={ranking} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setRanking(e.target.value)} />
            </div>
          </>
        )}
        {tipodisparo === 'Representante' && (
          <>
            <div className="form-group">
              <label htmlFor="codigos">Código(s) do Representante</label>
              <input type="text" id="codigos" value={codigos} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setCodigos(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="uf">UF</label>
              <input type="text" id="uf" value={uf} placeholder="Digite mais de um valor separando por vírgula, exemplo: RJ, SP, SC" onChange={(e) => setUf(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="diassemcompra">Dias Sem Vender</label>
              <input type="number" id="diassemcompra" value={diassemcompra} onChange={(e) => setDiassemcompra(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="ranking">Ranking</label>
              <input type="number" id="ranking" value={ranking} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setRanking(e.target.value)} />
            </div>
          </>
        )}
        {tipodisparo === 'Fornecedor' && (
          <>
            <div className="form-group">
              <label htmlFor="codigos">Códigos</label>
              <input type="text" id="codigos" value={codigos} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setCodigos(e.target.value)}  />
            </div>
            <div className="form-group">
              <label htmlFor="uf">UF</label>
              <input type="text" id="uf" value={uf} placeholder="Digite mais de um valor separando por vírgula, exemplo: RJ, SP, SC" onChange={(e) => setUf(e.target.value)}  />
            </div>
            <div className="form-group">
              <label htmlFor="ranking">Ranking</label>
              <input type="number" id="ranking" value={ranking} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setRanking(e.target.value)} />
            </div>
          </>
        )}
        {tipodisparo === 'Funcionários' && (
          <div className="form-group">
            <label htmlFor="codigos">Códigos</label>
            <input type="text" id="codigos" value={codigos} placeholder="Digite mais de um valor separando por vírgula, exemplo: 21, 45, 12" onChange={(e) => setCodigos(e.target.value)}  />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="dataenvio">Data de Envio</label>
          <InputMask mask="99/99/9999" id="dataenvio" value={dataenvio} onChange={(e) => setDataenvio(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="horainicial">Hora Inicial</label>
          <InputMask mask="99:99" id="horainicial" value={horainicial} onChange={(e) => setHorainicial(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="horafinal">Hora Final</label>
          <InputMask mask="99:99" id="horafinal" value={horafinal} onChange={(e) => setHorafinal(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="ativo">Ativo</label>
          <input type="checkbox" id="ativo" checked={ativo} onChange={(e) => setAtivo(e.target.checked)} />
        </div>
        <div className="form-group">
          <label htmlFor="repetir">Repetir Todo Dia</label>
          <input type="checkbox" id="repetir" checked={repetir} onChange={(e) => setRepetir(e.target.checked)} />
        </div>
        <button type="submit" className="disparo-button">Criar Disparo</button>
      </form>

      <h2>Disparos Criados</h2>
      <div className="disparo-table-container">
        <table className="disparo-table">
          <thead>
            <tr>
              <th>Tipo de Disparo</th>
              <th>Data de Envio</th>
              <th>Campanha</th>
              <th>Ativo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {disparos.map(disparo => (
              <tr key={disparo.id}>
                <td>{disparo.tipodisparo}</td>
                <td>{disparo.dataenvio}</td>
                <td>{disparo.campanhaNome}</td>
                <td>{disparo.ativo ? 'Sim' : 'Não'}</td>
                <td>
                  <button 
                    onClick={() => toggleAtivo(disparo.id)}
                    className={disparo.ativo ? 'deactivate-button' : 'activate-button'}
                  >
                    {disparo.ativo ? 'Desativar' : 'Ativar'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Disparo;
