import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import '../styles/MensagensPadraoManager.css';
const MensagensPadraoManager = () => {
  const [mensagensPadrao, setMensagensPadrao] = useState([]);
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('');

  useEffect(() => {
    const fetchMensagensPadrao = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/mensagens/padrao`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setMensagensPadrao(response.data);
      } catch (error) {
        toast.error('Erro ao carregar mensagens padrão', { position: 'top-right' });
      }
    };

    fetchMensagensPadrao();
  }, []);

  const handleAddMensagemPadrao = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${config.apiUrl}/mensagens/padrao`, 
        { titulo, mensagem }, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data) {
        toast.success('Mensagem padrão adicionada com sucesso', { position: 'top-right' });
        setMensagensPadrao([...mensagensPadrao, { titulo, mensagem }]);
        setTitulo('');
        setMensagem('');
      }
    } catch (error) {
      toast.error('Erro ao adicionar mensagem padrão', { position: 'top-right' });
    }
  };

  return (
    <div className="mensagens-padrao-container">
      <h2>Gerenciar Mensagens Padroes</h2>
      <ul>
        {mensagensPadrao.map((msg, index) => (
          <li key={index}>{msg.titulo}: {msg.mensagem}</li>
        ))}
      </ul>
      <div>
        <input 
          type="text" 
          placeholder="Título" 
          value={titulo} 
          onChange={(e) => setTitulo(e.target.value)} 
        />
        <textarea 
          placeholder="Mensagem" 
          value={mensagem} 
          onChange={(e) => setMensagem(e.target.value)} 
        />
        <button onClick={handleAddMensagemPadrao}>Adicionar Mensagem Padrão</button>
      </div>
    </div>
  );
};

export default MensagensPadraoManager;
