import React from 'react';
import '../styles/ServicoIndisponivel.css';
import servicoInd from '../assets/servico-indisponivel.png'

const ServicoIndisponivel = () => {
  return (
    <div className="servico-indisponivel-container">
      <div className="servico-indisponivel-content">
        <h2>Serviço Indisponível</h2>
        <p>Esse serviço não está disponível para sua empresa.</p>
        <img src={servicoInd} className='servico-indisponivel-img' alt="servico-indisponivel"/>
      </div>
    </div>
  );
};

export default ServicoIndisponivel;
