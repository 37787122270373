import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ManageMensagens.css';
import { toast } from 'react-toastify';
import config from '../config';
import msgImage from '../assets/messageImage.png'
const apiUrl = config.apiUrl;
const ManageMensagens = () => {
  const [mensagens, setMensagens] = useState([]);
  const [editingMensagem, setEditingMensagem] = useState(null);
  const [novaMensagem, setNovaMensagem] = useState('');

  useEffect(() => {
    const fetchMensagens = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${apiUrl}/mensagens`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setMensagens(response.data);
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
        toast.error('Erro ao buscar mensagens');
        window.location.reload()
      }
    };
    fetchMensagens();
  }, []);

  const handleEditClick = (mensagem) => {
    setEditingMensagem(mensagem);
    setNovaMensagem(mensagem.mensagem);
  };

  const handleCancelClick = () => {
    setEditingMensagem(null);
    setNovaMensagem('');
  };

  const handleSaveClick = async (mensagemId) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${apiUrl}/mensagens`, {
        mensagemId,
        novaMensagem,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setMensagens(mensagens.map(mensagem => 
        mensagem.id === mensagemId ? { ...mensagem, mensagem: novaMensagem } : mensagem
      ));
      setEditingMensagem(null);
      setNovaMensagem('');
      toast.success('Mensagem atualizada com sucesso');
    } catch (error) {
      console.error('Erro ao atualizar mensagem:', error);
      toast.error('Erro ao atualizar mensagem');
    }
  };

  return (
    <div className="manage-mensagens-container">
      <h2>Gerenciar Mensagens do Chatbot</h2>
      <img src={msgImage} alt="msg-image" className="msg-image" />
      <h5 >Observação: O "\n" é utilizado para pular linha no Whatsapp</h5>
      <h5 className="important-text">
        NÃO pode ser alterado a escrita que estiver entre chaves, como exemplo {"{NUM_PDV}"} caso contrário o robô não encontrará as variáveis.
      </h5>
      <div className="mensagens-list">
        {mensagens.map(mensagem => (
          <div key={mensagem.id} className="mensagem-item">
            <h3>{mensagem.titulo}</h3>
            {editingMensagem?.id === mensagem.id ? (
              <div className="edit-area">
                <textarea
                  value={novaMensagem}
                  onChange={(e) => setNovaMensagem(e.target.value)}
                />
                <button className="save-button" onClick={() => handleSaveClick(mensagem.id)}>Salvar</button>
                <button className="cancel-button" onClick={handleCancelClick}>Cancelar</button>
              </div>
            ) : (
              <div className="mensagem-content">
                <p>{mensagem.mensagem}</p>
                <button className="edit-button" onClick={() => handleEditClick(mensagem)}>Editar</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageMensagens;
