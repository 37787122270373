import React, { useState } from 'react';
import '../styles/HeaderUser.css';
import logo from '../assets/logo.png'; // Importar a logo
import carrinhoIcon from '../assets/carrinho-de-compras.png';
import conversasIcon from '../assets/clientes.png';
import sairIcon from '../assets/sair.png';
import seletorIcon from '../assets/seletores.png';
import statusIcon from '../assets/sinal.png'
import conversas2Icon from '../assets/conversas.png';
import newServicoIcon from '../assets/newservico.png';

const HeaderAdmin = ({ onNavItemClick, userName, onLogout}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`header-user-container ${isExpanded ? 'expanded' : 'collapsed'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="header-user-content">
        <img src={logo} alt="Logo" className="header-user-logo" /> 
        <div className="header-user-nav-button" onClick={() => onNavItemClick('clientes')}>
          <img src={conversasIcon} alt="Conversas" className="nav-icon" />
          <span>Clientes</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('servicos')}>
          <img src={carrinhoIcon} alt="Carrinho de Compras" className="nav-icon" />
          <span>Serviços</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('seletores')}>
          <img src={seletorIcon} alt="Seletores" className="nav-icon" />
          <span>Versionamento</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('status')}>
          <img src={statusIcon} alt="Status" className="nav-icon" />
          <span>Status</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('servicosP')}>
          <img src={newServicoIcon} alt="ServicosP" className="nav-icon" />
          <span>Criar Serviço</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('mensagensP')}>
          <img src={conversas2Icon} alt="MensagensP" className="nav-icon" />
          <span>Criar Mensagens</span>
        </div>
        <div className="header-user-nav-button" onClick={onLogout}>
          <img src={sairIcon} alt="Sair" className="nav-icon" />
          <span>Sair</span>
        </div>  
      </div>
      <div className="header-user-footer">
        <span className="footer-username">{userName}</span>
        <span className="footer-title">Área do Administrador</span>
      </div>
    </div>
  );
};

export default HeaderAdmin;
