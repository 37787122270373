import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import '../styles/ManageServices.css';
import config from '../config';
const apiUrl = config.apiUrl;
const ManageServices = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [servicos, setServicos] = useState([]);

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const fetchUsuarios = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/user`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setUsuarios(response.data);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      window.location.reload()
    }
  };

  const fetchServicos = useCallback(async () => {
    if (!selectedUser) return; 
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${apiUrl}/servicos/${selectedUser}`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setServicos(response.data.sort(compareServicos));
    } catch (error) {
      console.error('Erro ao buscar serviços:', error);
    }
  }, [selectedUser]);

  useEffect(() => {
    fetchServicos();
  }, [fetchServicos]);

  const compareServicos = (a, b) => a.codigo - b.codigo;

  const toggleService = async (servicoId, isActive) => {
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${apiUrl}/servicos`, {
        userId: selectedUser,
        servicoId: servicoId,
        isActive: isActive
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      fetchServicos(); 
    } catch (error) {
      console.error('Erro ao alterar status do serviço:', error);
    }
  };

  const toggleAllServices = async (activate) => {
    const token = localStorage.getItem('token');
    const promises = servicos
      .filter(servico => servico.ativo !== activate) // Only toggle services that need to be changed
      .map(servico => axios.put(`${apiUrl}/servicos`, {
        userId: selectedUser,
        servicoId: servico.codigo,
        isActive: activate
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      }));

    try {
      await Promise.all(promises);
      fetchServicos(); // Refresh the list after updating
    } catch (error) {
      console.error('Erro ao alterar status de todos os serviços:', error);
    }
  };

  return (
    <div className="manage-services-container">
      <h2>Gerenciamento de Serviços</h2>
      <select onChange={(e) => setSelectedUser(e.target.value)} value={selectedUser || ''} className="user-select">
        <option value="" disabled>Selecione um usuário</option>
        {usuarios.map(usuario => (
          <option key={usuario.id} value={usuario.id}>{usuario.nome}</option>
        ))}
      </select>
      {selectedUser && (
        <>
          <div className="action-buttons">
            <button className="unblock-button" onClick={() => toggleAllServices(true)}>Ativar Todos</button>
            <button className="block-button" onClick={() => toggleAllServices(false)}>Desativar Todos</button>
          </div>
          <table className="services-table">
            <thead>
              <tr>
                <th>Serviço</th>
                <th>Status</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {servicos.map(servico => (
                <tr key={servico.id}>
                  <td>{servico.servico}</td>
                  <td>{servico.ativo ? 'Ativo' : 'Inativo'}</td>
                  <td>
                    <button
                      className={servico.ativo ? 'block-button' : 'unblock-button'}
                      onClick={() => toggleService(servico.codigo, !servico.ativo)}
                    >
                      {servico.ativo ? 'Desativar' : 'Ativar'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default ManageServices;
