import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import '../styles/StatusTable.css'; // Estilos para o componente

const StatusTable = () => {
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    const fetchStatuses = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/status`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStatuses(response.data);
      } catch (error) {
        toast.error('Erro ao carregar os status dos serviços', { position: 'top-right' });
      }
    };

    fetchStatuses();
  }, []);

  return (
    <div className="status-table-container">
      <h2>Status dos Serviços</h2>
      <table className="status-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Status Java</th>
            <th>Status Node</th>
            <th>Última Atualização</th>
          </tr>
        </thead>
        <tbody>
          {statuses.map((status) => (
            <tr key={status.id}>
              <td>{status.autor}</td>
              <td>
                {status.java ? (
                  <span className="status-icon green">&#x2714;</span>
                ) : (
                  <span className="status-icon red">&#x2716;</span>
                )}
              </td>
              <td>
                {status.node ? (
                  <span className="status-icon green">&#x2714;</span>
                ) : (
                  <span className="status-icon red">&#x2716;</span>
                )}
              </td>
              <td>{new Date(status.data).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StatusTable;
