import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import logo from '../assets/logo2.png';
import robo from '../assets/robo.png';
import pc from '../assets/pc-home.png';
import mao from '../assets/mao-robo.png';
import infinito from '../assets/infinito.png';
import fotoEsp from '../assets/fundo-home5.png';
import fotoEspCell from '../assets/fundo-home5cell.png';
import logo5 from '../assets/Logo5.png';
import instagram from '../assets/instagram icon.png';
import linkedin from '../assets/linkedin icon.png';
import facebook from '../assets/Facebook icon.png';
import fone from '../assets/zap icon.png';
import email from '../assets/carta icon.png';
import logoHard from '../assets/logos-parcerios/logoHard.png';
import logoFischer from '../assets/logos-parcerios/logoFischer.png';
import logoRomanzza from '../assets/logos-parcerios/LogoRomanzza.png';
const Home = () => {
  useEffect(() => {
    const especificacoesImg = document.getElementById('especificacoes-img');
    const updateImage = () => {
      if (window.matchMedia('(max-width: 700px)').matches) {
        especificacoesImg.src = fotoEspCell;
      } else {
        especificacoesImg.src = fotoEsp;
      }
    };

    window.addEventListener('resize', updateImage);
    updateImage(); // Chama a função ao carregar a página

    return () => {
      window.removeEventListener('resize', updateImage);
    };
  }, []);

  return (
    <div className='all-home'>
      <div className="home-container">
        <header className="home-header">
          <img src={logo} alt="Company Logo" className="home-logo" />
          <nav className="home-nav">
            <Link to="/" className="home-nav-link">Home</Link>
            <Link to="/" className="home-nav-link">Sobre</Link>
            <Link to="/" className="home-nav-link">Contato</Link>
            <Link to="/" className="home-nav-link">Clientes</Link>
            <Link to="/cliente" className="home-client-area-link">Área do Cliente</Link>
          </nav>
        </header>
        <div className="home-content">
          <img src={robo} alt="Descrição" className="home-content-image robo-img" />
          <div className="descricao-container">
            <div className="descricao-texto">
              Os robôs inteligentes <br /> que agilizam seu dia a dia.
            </div>
            <img src={infinito} className="infinito-image" alt="Infinito" />
          </div>
          <img src={pc} alt="PC Home" className="home-content-image pc-image" />
          <img src={mao} alt="Mão Apontando" className="mao-apontando" />
        </div>
      </div>
      <div className="info-section">
        <img id="especificacoes-img" src={fotoEsp} alt="Especificações" className="especificacoes" />
      </div>
      <div className="clientes-container">
        <h2 className="clientes-title">CLIENTES QUE JÁ AGILIZAM SEUS ATENDIMENTOS</h2>
        <div className="logos-container">
          <img src={logoHard} alt="Logo 1" className="cliente-logo" />
          <img src={logoFischer} alt="Logo 2" className="cliente-logo" />
          <img src={logoRomanzza} alt="Logo 3" className="cliente-logo" />
        </div>
      </div>
      <div className="home-footer">
        <div className="footer-column">
          <img src={logo5} alt="Logo" className="footer-logo" />
          <div className="social-icons">
            <Link to="https://www.instagram.com/agilesolutionltda" target="_blank"><img src={instagram} alt="Instagram Icon" /></Link>
            <Link to="https://www.linkedin.com/company/81806785" target="_blank"><img src={linkedin} alt="LinkedIn Icon" /></Link>
            <Link to="https://www.facebook.com/agileSolution.com.br" target="_blank"><img src={facebook} alt="Facebook Icon" /></Link>
          </div>
        </div>
        <div className="footer-column">
          <h3>Entre em Contato</h3>
          <div className="contact-icons">
            <div className="contact-item">
              <img src={email} alt="Email Icon" />
              <span>gildonei@agilesolution.com.br</span>
            </div>
            <div className="contact-item">
              <img src={fone} alt="Phone Icon" />
              <span>(54) 98129-9854</span>
            </div>
          </div>
        </div>
        <div className="footer-copy">
          <p>© 2024 AgileSolution. Todos os direitos reservados.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
