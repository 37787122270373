import React, { useState } from 'react';
import '../styles/Login.css';
import logo from '../assets/logo.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
const apiUrl = config.apiUrl;
const Login = ({ onLoginSuccess, onAdminCheck }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, {
        login,
        password,
      });
      
      const { token, isAdmin, nome } = response.data;
      localStorage.setItem('token', token);
      onAdminCheck(isAdmin);
      onLoginSuccess(nome);
      toast.success('Login realizado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false, 
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'green',
          color: 'white',
        },
      });

    } catch (error) {
      setError('Login falhou. Por favor, verifique suas credenciais.');
      toast.error('Usuário ou senha incorretos.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: 'red',
          color: 'white',
        },
      });
      console.error('Erro ao fazer login:', error);
    }
  };

  const handleForgotPassword = () => {
    window.location.href = '/forgot-password'; // Redireciona para a página de recuperação de senha
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Company Logo" className="logo" />
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Faça sua autenticação</h2>
        <div className="form-group">
          <input
            type="text"
            id="username"
            name="username"
            placeholder='Nome de usuário'
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            name="password"
            placeholder='Senha'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className="error">{error}</p>}
        <button type="submit" className="login-button">Login</button>
        <p className="forgot-password-link" onClick={handleForgotPassword}>Esqueci minha senha</p>
      </form>
    </div>
  );
};

export default Login;
