import React from 'react';
import celularCampanha from '../assets/celularCampanha.png';
import chatCampanha from '../assets/chat1campanha.png';
import '../styles/Popup.css';

const Popup = ({ selectedCampanha, imagemSrc, handleClosePopup }) => {
  const getMessageBoxClass = () => {
    
    if (selectedCampanha.conteudo.length < 50) {
      return 'chat-image-50';
    }
    else if (selectedCampanha.conteudo.length < 100) {
        return 'chat-image-100';
    }
    else if (selectedCampanha.conteudo.length < 150) {
        return 'chat-image-150';
    }
    else if (selectedCampanha.conteudo.length < 200) {
        return 'chat-image-200';
    }
    else if (selectedCampanha.conteudo.length < 250) {
        return 'chat-image-250';
    }
    else if (selectedCampanha.conteudo.length < 309) {
        return 'chat-image-300';
    }
    else if (selectedCampanha.conteudo.length < 360) {
        return 'chat-image-360';
    }
    else {
      return 'chat-image-400';
    }
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <button className="close-button" onClick={handleClosePopup}>Fechar</button>
        <div className="whatsapp-simulation">
          <img src={celularCampanha} alt="Celular" className="celular-image" />
          <div className="message-box">
            {imagemSrc && <img src={imagemSrc} alt="Imagem da Campanha" className="popup-image" />}
            <div className="message-content">
              <img src={chatCampanha} alt="Chat" className={`chat-image ${getMessageBoxClass()}`} />
              <div className="popmsg-container">
                <p className="popmsg">{selectedCampanha.conteudo}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
