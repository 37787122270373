import React, { useState, useEffect } from 'react';
import '../styles/UpdateUserInfo.css'; // Adicione a estilização no CSS
import config from '../config';
const apiUrl = config.apiUrl;
const UpdateUserInfo = () => {
  const [userInfo, setUserInfo] = useState({ nome: '', login: '' });
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senhaNova, setSenhaNova] = useState('');
  const [confirmSenhaNova, setConfirmSenhaNova] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found in localStorage');
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/user/info`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setUserInfo(data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        window.location.reload()
      }
    };

    fetchUserInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/auth/update`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nome: userInfo.nome, login: userInfo.login }),
      });

      if (response.ok) {
        alert('Informações atualizadas com sucesso!');
      } else {
        alert('Falha ao atualizar informações.');
      }
    } catch (error) {
      console.error('Error updating user info:', error);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (senhaNova !== confirmSenhaNova) {
      alert('As novas senhas não coincidem.');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('Token not found in localStorage');
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/auth/update/password`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ senhaAntiga, senhaNova }),
      });

      if (response.ok) {
        alert('Senha alterada com sucesso!');
        setSenhaAntiga('');
        setSenhaNova('');
        setConfirmSenhaNova('');
        setShowPasswordForm(false);
      } else {
        alert('Senha antiga incorreta.');
      }
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  return (
    <div className="update-container">
      <h1>Atualizar Informações de Usuário</h1>
      <form onSubmit={handleSubmit} className="update-form">
        <div className="update-form-group">
          <label>Nome:</label>
          <input
            type="text"
            value={userInfo.nome}
            onChange={(e) => setUserInfo({ ...userInfo, nome: e.target.value })}
            required
          />
        </div>
        <div className="update-form-group">
          <label>Email:</label>
          <input
            type="email"
            value={userInfo.login}
            onChange={(e) => setUserInfo({ ...userInfo, login: e.target.value })}
            required
          />
        </div>
        <button type="submit" className="update-submit-button">Atualizar Informações</button>
      </form>

      <button onClick={() => setShowPasswordForm(!showPasswordForm)} className="update-toggle-password-form-button">
        {showPasswordForm ? 'Cancelar' : 'Alterar Senha'}
      </button>

      {showPasswordForm && (
        <form onSubmit={handlePasswordSubmit} className="update-form">
          <div className="update-form-group">
            <label>Senha Antiga:</label>
            <input
              type="password"
              value={senhaAntiga}
              onChange={(e) => setSenhaAntiga(e.target.value)}
              required
            />
          </div>
          <div className="update-form-group">
            <label>Nova Senha:</label>
            <input
              type="password"
              value={senhaNova}
              onChange={(e) => setSenhaNova(e.target.value)}
              required
            />
          </div>
          <div className="update-form-group">
            <label>Confirmar Nova Senha:</label>
            <input
              type="password"
              value={confirmSenhaNova}
              onChange={(e) => setConfirmSenhaNova(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="update-submit-button">Alterar Senha</button>
        </form>
      )}
    </div>
  );
};

export default UpdateUserInfo;
