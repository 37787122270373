import React from 'react';

const MessageContainer = ({ title, mensagens, className, nomeRobo, atendenteNumero }) => {
  return (
    <div className={`message-container ${className}`}>
      <h3>{title}</h3>
      <div className="message-list">
        {mensagens.length > 0 ? (
          mensagens.map((msg, index) => {
            let messageClass = 'user';
            if (msg.remetente === atendenteNumero) {
              messageClass = 'atendente';
            } else if (msg.remetente === 'bot') {
              messageClass = 'bot';
            }

            return (
              <div key={index} className={`message ${messageClass}`}>
                <p><strong>{msg.remetente === 'bot' ? nomeRobo : msg.remetente === atendenteNumero ? 'Atendente' : msg.remetente}:</strong> {msg.mensagem}</p>
                <span>{new Date(msg.data).toLocaleString()}</span>
              </div>
            );
          })
        ) : (
          <p>Nenhuma mensagem encontrada.</p>
        )}
      </div>
    </div>
  );
};

export default MessageContainer;
