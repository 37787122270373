import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config';
import '../styles/MensagensPadraoManager.css';
const ServicosPadraoManager = () => {
  const [servicosPadrao, setServicosPadrao] = useState([]);
  const [servico, setServico] = useState('');
  const [codigoServico, setCodigoServico] = useState('');

  useEffect(() => {
    const fetchServicosPadrao = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${config.apiUrl}/servicos/padroes`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setServicosPadrao(response.data);
      } catch (error) {
        toast.error('Erro ao carregar serviços padrão', { position: 'top-right' });
      }
    };

    fetchServicosPadrao();
  }, []);

  const handleAddServicoPadrao = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${config.apiUrl}/servicos/padroes/add`, 
        { servico, codigoservico: parseInt(codigoServico) }, 
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data) {
        toast.success('Serviço padrão adicionado com sucesso', { position: 'top-right' });
        setServicosPadrao([...servicosPadrao, { servico, codigoservico: parseInt(codigoServico) }]);
        setServico('');
        setCodigoServico('');
      }
    } catch (error) {
      toast.error('Erro ao adicionar serviço padrão', { position: 'top-right' });
    }
  };

  return (
    <div className="servicos-padrao-container">
      <h2>Gerenciar Serviços Padroes</h2>
      <ul>
        {servicosPadrao.map((svc, index) => (
          <li key={index}>{svc.servico}: {svc.codigoservico}</li>
        ))}
      </ul>
      <div>
        <input 
          type="text" 
          placeholder="Serviço" 
          value={servico} 
          onChange={(e) => setServico(e.target.value)} 
        />
        <input 
          type="number" 
          placeholder="Código do Serviço" 
          value={codigoServico} 
          onChange={(e) => setCodigoServico(e.target.value)} 
        />
        <button onClick={handleAddServicoPadrao}>Adicionar Serviço Padrão</button>
      </div>
    </div>
  );
};

export default ServicosPadraoManager;
