import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import config from '../config';

const FeedLineChart = ({ periodo }) => {
  const [chartData, setChartData] = useState({
    series: [{ name: 'Mensagens', data: [] }],
    options: {
      chart: {
        type: 'line',
        height: 350,
        toolbar: { 
          show: false
        }
      },
      xaxis: {
        categories: []
      },
      
      title: {
        text: 'Frequência de Mensagens por Dia',
        align: 'center'
      }
      
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiUrl}/historico/mensagens-por-periodo`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { periodo }
      });

      const categorias = response.data.map(item => item.dia);
      const dados = response.data.map(item => item.quantidade);

      setChartData({
        series: [{ name: 'Mensagens', data: dados }],
        options: {
          ...chartData.options,
          xaxis: { categories: categorias }
        }
      });
    };

    fetchData();
  }, [periodo]);

  return (
    <div>
      <Chart options={chartData.options} series={chartData.series} type="line" height={350} />
    </div>
  );
};

export default FeedLineChart;
