import React, { useState } from 'react';
import HeaderAdmin from './HeaderAdmin';
import RegisterAdmin from './RegisterAdmin';
import ManageServices from './ManageServices';
import Seletores from './Seletores';
import StatusTable from './StatusTable';
import MensagensPadraoManager from './MensagensPadraoManager';
import ServicosPadraoManager from './ServicosPadraoManager';
// Importe outros componentes conforme necessário

const AdminDashboard = ({userName, onLogout}) => {
  const [activeComponent, setActiveComponent] = useState('servicos');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'servicos':
        return <ManageServices/>;
      case 'clientes':
        return <RegisterAdmin/>; 
      case 'seletores':
        return <Seletores/>;
      case 'status':
        return <StatusTable/>;
      case 'servicosP':
        return <ServicosPadraoManager/>;
      case 'mensagensP':
        return <MensagensPadraoManager/>;
      default:
        return <h1>Recarregue a página</h1>;
    }
  };

  return (
    <div>
      <HeaderAdmin onNavItemClick={setActiveComponent} userName={userName} onLogout={onLogout}/>
      <div className="content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default AdminDashboard;
