import React, { useState } from 'react';
import '../styles/HeaderUser.css';
import logo from '../assets/logo.png'; // Importar a logo
import carrinhoIcon from '../assets/carrinho-de-compras.png';
import conversasIcon from '../assets/conversas.png';
import configIcon from '../assets/definicoes.png';
import perfilIcon from '../assets/perfil.png';
import historicoIcon from '../assets/historico.png';
import atendentesIcon from '../assets/atendentes.png';
import sairIcon from '../assets/sair.png';
import campanhaIcon from '../assets/disparamos.png';
import disparosIcon from '../assets/campanha.png';
import authcadeado from '../assets/authcadeado.png';
import analytics from '../assets/analytics.png';

const HeaderUser = ({ onNavItemClick, userName, onLogout }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`header-user-container ${isExpanded ? 'expanded' : 'collapsed'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="header-user-content">
        <img src={logo} alt="Logo" className="header-user-logo" /> {/* Adicionar a logo */}
        <div className="header-user-nav-button" onClick={() => onNavItemClick('servicos')}>
          <img src={carrinhoIcon} alt="Carrinho de Compras" className="nav-icon" />
          <span>Serviços</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('parametros')}>
          <img src={conversasIcon} alt="Conversas" className="nav-icon" />
          <span>Mensagens</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('parametros2')}>
          <img src={configIcon} alt="Configurações" className="nav-icon" />
          <span>Parâmetros</span>
        </div>

        <div className="header-user-nav-button" onClick={() => onNavItemClick('atendentes')}>
          <img src={atendentesIcon} alt="Atendentes" className="nav-icon" />
          <span>Atendentes</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('historico')}>
          <img src={historicoIcon} alt="Histórico" className="nav-icon" />
          <span>Histórico</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('campanhas')}>
          <img src={campanhaIcon} alt="Campanhas" className="nav-icon" />
          <span>Campanhas</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('disparos')}>
          <img src={disparosIcon} alt="Disparos" className="nav-icon" />
          <span>Disparos</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('autenticacao')}>
          <img src={authcadeado} alt="autenticacao" className="nav-icon" />
          <span>Autenticação</span>
        </div>
        <div className="header-user-nav-button" onClick={() => onNavItemClick('feedback')}>
          <img src={analytics} alt="feedback" className="nav-icon" />
          <span>Feedbacks</span>
        </div>

        <div className="header-user-nav-button" onClick={() => onNavItemClick('conta')}>
          <img src={perfilIcon} alt="Perfil" className="nav-icon" />
          <span>Conta</span>
        </div>
        <div className="header-user-nav-button" onClick={onLogout}>
          <img src={sairIcon} alt="Sair" className="nav-icon" />
          <span>Sair</span>
        </div>
      </div>
      <div className="header-user-footer">
        <span className="footer-username">{userName}</span>
        <span className="footer-title">Área do Cliente</span>
      </div>
    </div>
  );
};

export default HeaderUser;
