import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const FeedPeriodSelector = ({ onSelect, apiUrl }) => {
  const [periodos, setPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState('');

  useEffect(() => {
    const fetchPeriodos = async () => {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${config.apiUrl}/${apiUrl}/periodos`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPeriodos(response.data);
      if (response.data.length > 0) {
        setSelectedPeriodo(response.data[0]);
        onSelect(response.data[0]);
      }
    };

    fetchPeriodos();
  }, [apiUrl, onSelect]);

  const handleChange = (e) => {
    setSelectedPeriodo(e.target.value);
    onSelect(e.target.value);
  };

  return (
    <div>
      <label htmlFor="periodo">Selecione o Período:</label>
      <select id="periodo" value={selectedPeriodo} onChange={handleChange}>
        {periodos.map((periodo, index) => (
          <option key={index} value={periodo}>
            {periodo}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FeedPeriodSelector;
